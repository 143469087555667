.orderheading{
/* border: 1px solid red; */
}
.order-adders{
    /* height: 300px; */
    width: 100%;
    border: 2px;
    padding: 20px;
    margin-top: 20px;
   /* box-shadow:1px 0px 3px 0px; */
   box-shadow:0px 0px 1px 1px #949290;

}

.order-table-image{
    width:70px;
    height: 70px;


}
.order-table-image img{
    width: 100%;
    height: 100%;
    border-radius: 50%;

}
.delivery-address-producet p{
    margin-bottom: 0px;
}
.order-adders-image{
    height: 204px;
}
.order-adders-image img{
    height: 100%;
    width: 100%;
    border-radius: 5px;
}
.trackbutton-cancel{
    background-color: #AA8C72;
    border: #AA8C72;
}
.trackbutton-cancel:hover{
    background-color: #AA8C72;
    border: #AA8C72;
}
.order-table-status{
    border:1px solid #AA8C72;
    background-color: #AA8C72;
    color: white;
    border-radius: 2px;
}









/* responsive   425 */
@media only screen and (max-width:425px){
    .order-adders {
        padding: 14px;
    }
    .order-adders h4{
        font-size: 14px;
    }
    .order-adders h6{
        font-size: 12px;
    }
    .order-adders p{
        font-size: 13px;
    }
    .trackbutton-cancel {
        background-color: #AA8C72;
        border: #AA8C72;
        font-size: 13px;
    }
}