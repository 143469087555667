.footer{
    background-color: #AA8C72;
    padding: 40px 40px ;
}
.footer-bottom{
    background-color: #AA8C72;
    border-top: 1px solid #fff;
    padding: 10px 0px;
}
.footer-bottom span{
    cursor: pointer;
}

.payment-image{
    width: 80px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.payment-image img{
    width: 60px; 
}
.footer-first-section ul{
padding: 0px;
}
.footer-first-section ul li{
    list-style: none;
    color: #fff;
    cursor: pointer;
    padding: 3px 0px;
   
}
.footer-first-section ul li:hover{
    text-decoration: underline;
}
.footer-first-section ul li:first-child{
    list-style: none;
    color: #ffffff;
    font-weight: 700;
    font-size: 24px;
    padding: 0px 0px 20px 0px;
}

.footer-second-section ul{
    padding: 0px;
}

.footer-second-section ul li{
    list-style: none;
    color: #fff;
    cursor: pointer;
    padding: 3px 0px;
    font-size: 20px;
}
.footer-second-section ul li:first-child{
    list-style: none;
    color: #ffffff;
    font-weight: 700;
    font-size: 24px;
    padding: 0px 0px 20px 0px;
}
.store-img img{
    margin: 0px 5px;
    border: 1px solid #fff;
    border-radius: 5px;
}

.footer-social-icon svg{
    margin: 0px 15px 0px 0px;
    border-radius: 50%;
    border: 1px solid #fff;
    width: 30px;
    height: 30px;
    padding: 5px;
}

@media only screen and (max-width:1024px){
    /* .footer-second-section ul li:first-child{
  font-size: 19px;
}
.footer-first-section ul li:first-child {
    font-size: 17px;
} */
}
@media (min-width:767px) and (max-width:800px){
    .footer-second-section ul li:first-child {
        font-size: 20px;
    }
    .footer-first-section ul li:first-child{
        font-size: 20px;
    }

}

@media only screen and (max-width:768px){
    .store-img img {
        margin: 0px 5px;
        border: 1px solid #fff;
        border-radius: 5px;
        margin-top: 5px;
    }
}

@media only screen and (max-width:525px){
    .store-img img {
        margin: 0px 5px;
        border: 1px solid #fff;
        border-radius: 5px;
        margin-top: 5px;
    }
}