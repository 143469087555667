

.discount-price{
    color: grey;
    font-size: 14px;
    text-decoration: line-through;
}

.allproducts-product-main{
 width: 100%;   
}


.allproducts-product-main ul{
    padding: 0px;
    width: 100%; 
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0px;
}
.allproducts-product-main ul li{
    width: 20%;
    list-style: none;
    padding: 2px 10px;
    cursor: pointer;
    transition: transform .5s;
}
.allproducts-product-main ul li a{
    color: #000;
    text-decoration: none;
}
.allproducts-product-main ul li:hover {
    -ms-transform: scale(1); 
    -webkit-transform: scale(1); 
    transform: scale(1.04); 
  }
.allproducts-background-div{
    width: 100%;
}

.tag-name-product {
    color:grey;
    font-size:12px;
    margin: 5px 0px 0px 0px;
    padding: 0px;
}
.allproducts-background-div span{
    position: absolute;
    background: #000 ;
    border-radius: 27px;
    font-size: 11px;
    color: #fff;
    padding: 5px 15px;
    opacity: 0.8;
    font-weight: bold;
    rotate: 399deg;
    margin: 20px 0px 0px 32px;
}
.allproducts-product-main ul li img{
    width: 100%;
    height: 300px;
    
}


.allproducts-product-main ul li h5{
    margin: 0px;
    padding: 0px;
    text-align: start;
    font-weight: 600;
    font-size: 13px;
    padding: 7px 0px;
    letter-spacing: 0.8px;
}

.allproducts-product-main ul li p{
    margin: 0px;
    padding: 0px 0px;
    font-size: 11px;
    font-weight: 600;
}

.allproducts-product-main ul li span{
    /* color:grey; */
    font-size: 10px;
    font-weight: 500;
}
.allproducts-product-main ul li h6{
    margin: 0px;
    padding: 10px 0px 0px 0px;
    color: grey;
    font-size: 10px;
    position: absolute;
}
/* .allproducts-product-main ul li button{
    background-color: #000;
    outline: none;
    border: none;
    border-radius: 5px;
    color: #fff;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
} */
.allproducts-product-main ul li svg{
    font-size: 25px;
    margin: 20px;
    color:#000
}

.add-to-cart-button button{
    padding: 4px 15px;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 8px;
    font-size: 12px;
    
}
.add-to-cart-button button:hover{
    background-color: #000;
    border: 1px solid #000;
    color: #fff;
} 

.all-product-sub-heading {
    font-weight: 600;
    padding: 0px 10px;
}


/* responsibve 1024 */
@media only screen and (max-width:1024px){
    .allproducts-product-main ul li{
        width:25%
    }
}
/* responsibve 768 */
@media only screen and (max-width:768px){
    .allproducts-product-main ul li{
        width:33.33%
    }
}
/* responsibve 525 */
@media only screen and (max-width:525px){
    .allproducts-product-main ul li{
        width: 100%;
    }
    .allproducts-background-div span{
        margin: 16px 0px 0px 166px;
    }
}
@media only screen and (max-width:425px){
    .allproducts-product-main ul li{
        width: 100%;
    }
    .allproducts-background-div span{
        margin: 15px 0px 0px 120px;
    }
}
@media only screen and (max-width:375px){
    .allproducts-product-main ul li{
        width: 100%;
    }
    .allproducts-background-div span{
        margin: 15px 0px 0px 95px;
    }
}
@media only screen and (max-width:330px){
    .allproducts-product-main ul li{
        width: 100%;
    }
    .allproducts-background-div span{
        margin: 17px 0px 0px 60px;
    }
}