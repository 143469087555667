.react-slider__areaZoom img {
    z-index: 2;
    position: relative;
    height: 500px !important;
  }
  .react-slider__areaZoom{
    position: relative !important;
  }
  .product_slider{
    position: relative;
    top: 0;
  }
 .heart_icon_wishlist{
  position: absolute;
  z-index: 9999;
  right: 60px;
  top: 20px;
  background-color: aliceblue;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
 }
.heart_icon_wishlist svg{
 font-size: 20px;
}
  .product_page_loader{
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .react-slider__container button{
    right: 1px !important;
    background-color: #000 !important;
    opacity: 0.5 !important;
  }
  .react-slider__ul{
    margin: 0px !important;
    padding: 0px !important;
    justify-content: center !important;
  }
  .react-slider__ul li{
    height: 110px !important;
  }

  /* .product_right:hover .react-slider__imgZoom{
    display: none !important;
    left: 0% !important;
  }

  .react-slider__imgZoom:hover {
    display: none;
  } 
  
 .react-slider__areaZoom:hover .react-slider__imgZoom{
    display: block;
  }  */

  .product-right-side-information{
    padding: 20px 0px;
    border-bottom: 1px solid rgba(170, 140, 114, 1);
   
  }

  .product-right-side-information-polyester{
    color: rgba(170, 140, 114, 1);
  }
  
  .product-right-side-information-title{
    color: #000;
    font-weight: 600;
    font-size: 16px
  }
  .product-right-side-information-title-base{
    color: grey;
    font-size: 14px
  }
  .product-right-side-information-rating{
    border: 1px solid rgba(170, 140, 114, 1);
    background-color: antiquewhite;
    font-size: 12px;
    padding: 0px 5px;
    justify-content: center;
    align-items: center;
    margin: 0px 10px 0px 0px;
  }
  .product-right-side-information-rating svg{
   font-size: 12px;
  }
  .product-right-side-information-rating-main{
    display: flex;
    align-items: center;
    color: grey;
    font-size: 12px;
  }
.product-right-side-information-price{
font-size: 20px;
font-weight: 600;
}
.product-right-side-information-discount{
   font-size: 17px;
   color: grey; 
   font-weight: 500;
   text-decoration: line-through;
   padding: 0px 20px;
}
.product-right-side-information-off{
    font-size: 20px;
font-weight: 600;
color: rgba(170, 140, 114, 1);
}
.product-right-side-information-taxs{
    color: rgba(170, 140, 114, 1);
}
.select-size span{
    color: grey;
    cursor: pointer;
    font-size: 14px;
}
.select-size span:hover{
    text-decoration: underline;
}
.products-sizes ul li{
   list-style: none;
   border: 1px solid rgba(170, 140, 114, 1);
   padding: 5px;
   border-radius: 50%;
   width: 40px;
   height: 40px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   font-size: 13px;
}
.products-sizes ul li:hover{
  list-style: none;
  border: 1px solid rgba(170, 140, 114, 1);
  background-color: rgba(170, 140, 114, 1);
  color: #fff;
}

.products-color ul li{
  list-style: none;
  border: 1px solid rgba(170, 140, 114, 1);
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  padding: 6px 40px;
}
.products-color ul li:hover{
 list-style: none;
 border: 1px solid rgba(170, 140, 114, 1);
 background-color: rgba(170, 140, 114, 1);
 color: #fff;
}

.products-buttons button{
    background-color: rgba(170, 140, 114, 1);
    padding: 10px 40px;
    border-radius: 5px;
    outline: none;
    border: none;
    color: #fff;
    font-size: 14px;
}
.selected-size-main{
    border-bottom: 1px solid rgba(170, 140, 114, 1);
}

.delivery-option {
  border-bottom: 1px solid rgba(170, 140, 114, 1);
}
.delivery-option h6{
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 600;
}
.delivery-option input{
  outline: none;
  border-radius: 5px;
  padding: 5px 20px 5px 20px;
  border: 1px solid grey;
}
.delivery-option span{
  position: relative;
  font-size: 13px;
  right: 80px;
  color: rgba(170, 140, 114, 1);
  cursor: pointer;
}

.delivery-option ul{
  margin: 0px;
  padding: 20px 0px;
}
.delivery-option ul li{
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px;
}
.delivery-option ul li p{
  margin: 0px 0px 0px 20px;
  padding: 0px;
  font-size: 14px;

}
.delivery-option ul li img{
  width: 20px;
  height: 20px;
}
.delivery-option p{
  margin: 0px;
  padding: 10px 0px;
  font-size: 13px;
}

.product-information h6{
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 600;
}
.product-information img{
  width: 20px;
  height: 20px;
}

.product-information p{
  font-size: 13px;
  font-weight: 600;
}
.product-information span{
  font-size: 13px;
}
.product-information svg{
  cursor: pointer;
}
.product-information-single {
  border-bottom: 1px solid rgba(170, 140, 114, 1);
  padding: 8px 0px;
}

.about-product {
  padding: 10px 0px;
  border-bottom: 1px solid rgba(170, 140, 114, 1);
}
.about-product h6{
  font-size: 14px;
  padding: 10px 0px;
  font-weight: 600;
}

.about-product img{
width: 130px;

}
.about-product p{
  margin: 0px 10px;
  font-size: 13px;
}
.product-rating{
  display: flex;
  padding: 20px 0px;
  border-bottom: 1px solid rgba(170, 140, 114, 1);
}
.product-rating-1 h6{
  font-size: 14px;
  font-weight: 600;
}
.product-rating-1 span{
  font-size: 32px;
}
.product-rating-1 svg{
  font-size: 18px;
  color: rgba(170, 140, 114, 1);
}
.product-rating-2{
  width: 50%;
}

.product-rating-2 ul li{
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-rating-2 ul li svg{
  font-size: 18px;
  color: rgba(170, 140, 114, 1);
  margin: 0px 5px;
}

.product-rating-2 ul li span{
  font-size: 14px;
  margin: 0px 5px;
}

.products-page-path-title{
  font-size: 12px;
  color: grey;
}


.selectRootAttributesClass {
  background-color: #AA8C72;
  color: #fff;
}

.cart_degree {
  padding: 6px 10px;
  border: 1px solid grey;
  display: inline;
  border-radius: 5px;
}

.cart_degree svg :focus{
box-shadow: none;
outline: 0;
}

.cart_degree svg{
  font-size: 18px;
  margin: 0px 15px;
  cursor: pointer;
}
.addcartlength-item-show{
  display: none;
  position: fixed;
  width: 100%;
  /* top:50px; */
  border:2px solid #AA8C72;
  border-radius: 5px;
  /* padding: 10px; */
  bottom: 20px;
  background-color:#AA8C72;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color :white;

}
.addcartlength-item-show{
  display: none;
}
#product-right-id{
  height: 1300px;
  overflow-y: scroll;
  scrollbar-color: transparent transparent;

}
#product-right-id::-webkit-scrollbar {
  display: none;
}
/* responsive 1024px */
@media only screen and (max-width:1024px){
  .addcartlength-item-show{
    display: none;
  }
  .react-slider{
    /* width:0px */
   width: 100% !important;
  }
  .react-slider__areaZoom{
    width:100% !important;
  }
  .react-slider__imgZoom {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translateX(20px);
    /* width: 500px; */
    height: 500px;
    background-repeat: no-repeat;
    border: thin solid #ccc;
    opacity: 0;
    z-index: -9999;
    transition: opacity .3s ease-in-out;
    width: 100%;
}
}

/* responsive 768    */
@media only screen and (max-width:768px){
  .addcartlength-item-show{
    display: none;
  }
  .react-slider{
    /* width:0px */
   width: 100% !important;
  }
  .products-color ul li {
    padding: 4px 20px;
  }
  .react-slider__areaZoom{
    width:100% !important;
  }
  .react-slider__imgZoom {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translateX(20px);
    /* width: 500px; */
    height: 500px;
    background-repeat: no-repeat;
    border: thin solid #ccc;
    opacity: 0;
    z-index: -9999;
    transition: opacity .3s ease-in-out;
    width: 100%;
}
#product-right-id{
  height: auto;
  overflow-y: none;

}
}
/* responsive 525*/
@media only screen and (max-width:525px){
  .react-slider__imgZoom {
    display: none;
  }
  .addcartlength-item-show{
    width:93%; 
    display: flex;
    
  }
  .products-buttons button{
   padding: 10px 30px;
  }
}