.parentcategory-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    cursor: pointer;
}

.parentcategory-main img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
}
.parentcategory-main p{
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
    padding: 10px 0px 0px 0px;
}
@media only screen and (max-width:525px){
    .parentcategory-main img{
        height: 70px;
        width: 70px;
    }
    .parentcategory-main p {
        font-size: 12px;
    }


    
}
@media only screen and (max-width:380px){
    .parentcategory-main img{
        height: 50px;
        width: 50px;
    }
    .parentcategory-main p {
        font-size: 12px;
    }


    
}