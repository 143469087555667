.login {
    height: 50vh;
}


.login-right {
    padding: 10px 100px 10px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-right h3 {
    font-weight: bold;
    font-size: 26px;
}

.login-right .enter-details {
    color: rgb(157, 131, 98);
    margin: 0px;
    padding: 0px;
    font-size: 15px;
    font-weight: 500;
}

.login-right input[type=text] {
    width: 100%;
    outline:none;
    border-radius: 5px;
    border: 1px solid grey;
    font-size: 13px;
    padding: 12px 26px;
}

.login-right input[type=password] {
    width: 100%;
    outline:none;
    border-radius: 5px;
    border: 1px solid grey;
    font-size: 13px;
    padding: 12px 26px;
}


.login-right .create-btn {
    background-color: #ddd;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 5px;
    color: #000;
}

.login-right .log-in-btn {
    height: 40px;
    background-color: #f4e3cf;
    font-size: 14px;
    font-weight: 600;
    border: none;
    outline: none;
    border-radius: 5px;
}

.login-left img {
    width: 100%;
    height: 100vh;
}

.bottom-paragraph {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.login-right .connect {
    border-left: 45px solid black;
    border-right: 45px solid black;
    height: 1px;
    font-size: 12px;
    margin: 0px;
    line-height: 0px;
    padding: 0px 0px 0px 7px
}

.login-right .bottom-button {
    font-size: 13px;
    height: 40px;
    outline: none;
    border: none;
    border-radius: 5px;

}

.login-right .bottom-button i {
    font-size: 17px;
    padding-right: 15px;
}
.bottom-button svg{
   color: #fff;
  font-size: 20px;
  margin: 0px 10px 0px 0px;
}

.login-already-account{
    color: grey;
    font-size: 13px;
}
.login-already-account span{
    color: blue;
    cursor: pointer;
}
.login-already-account span:hover{
    text-decoration: underline;
}

.login_form svg{
 font-size: 20px;
 color: rgba(170, 140, 114, 1);
}

.login_heading{
    font-size: 16px;
}

.login_form input:focus{
    outline: none;
    box-shadow: none;
    border: 1px solid rgba(170, 140, 114, 1);
}
.login_title{
    font-weight: 600;
}

.login_button{
    background-color: rgba(170, 140, 114, 1);
    color: #fff;
    padding: 6px 30px;
    text-align: center;
    outline: none;
    border: none;
    border-radius: 5px;
    font-size: 15px;
}
@media screen and (max-width:1024px){
    .login-right{
        padding: 10px 50px;
    }
}

@media screen and (max-width:576px){
    .login-right{
        padding: 10px 10px;
    }
}