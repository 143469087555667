.bannermiddlepage{
    background-image: url(/public/img/bannermiddle.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 20px;
    margin: 50px 0px 0px 0px;
  }
  
  .bannermiddle-1 h4{
  color: #AA8C72;
  }
  
  .bannermiddle-1 h2{
   line-height: 40px;
  }
  
  .bannermiddle-1 p{
    margin: 0px;
    padding: 0px;
    color: grey;
  }
  
  .bannermiddle-1 button{
    background-color: #AA8C72;
    border-radius: 10px;
    padding: 8px 30px;
    outline: none;
    border: none;
    color: #fff;
    margin: 30px 0px 0px 0px;
  }


  /* responsive 1028 */
  @media only screen and (max-width:1028px){
    .bannermiddlepage{
      padding: 50px 20px;
    }
    .bannermiddle-1 h2 {
      line-height: 40px;
      font-size: 22px;
  }
  }

  @media only screen and (max-width:800px){
    .bannermiddlepage{
      padding: 30px 20px;
    }
    
    .bannermiddle-1 h2 {
      line-height: 30px;
      font-size: 20px;
    }
    .bannermiddle-1 p{
      font-size: 14px;
    }
    .bannermiddle-1 button {
      font-size: 14px;
    }
   
  }
  @media only screen and (max-width:525px){
    .bannermiddlepage{
      padding: 30px 20px;
      background-position: 70%;
    }
    .bannermiddle-1 h4{
      font-size: 16px;
    }
    .bannermiddle-1 h2 {
      line-height: 20px;
      font-size: 14px;
    }
    .bannermiddle-1 button{
      font-size: 14px;
      padding: 6px 20px;
    }
    #middlebanner{
      width: 45%;
    }
  }
  @media only screen and (max-width:375px){
    #middlebanner{
      width: 80%;
      z-index: 999999;
      /* color:black; */
    }
    .bannermiddle-1 h4{
      font-size: 14px;
      z-index: 9999;
      opacity: 1;
    }
    .bannermiddle-1 h2 {
      line-height: 20px;
      font-size: 12px;
    }
  }
