.sale-image img{
    width: 100%;
    height: 200px;
}

.fashionsale-background{
    background-color:  #F1EBE3;
    padding: 50px;
    /* margin-right: 1px; */
    border-right: 5px solid #fff;
}


.fashionsale-background-1{
    background-color:  #F1EBE3;
    padding: 50px;
}

.sale-text button{
    background-color: #AA8C72;
  border-radius: 12px;
  padding: 8px 30px;
  outline: none;
  border: none;
  color: #fff;
  margin: 30px 0px 0px 0px;
}

@media only screen and (max-width:800px){
    .fashionsale-background {
      padding: 40px;
    }
    .fashionsale-background-1 {
      padding: 40px;
    }
    .sale-text h2{
      font-size: 20px;
    }
    .sale-text h4{
      font-size: 16px;
    }
    .sale-text p{
      font-size: 14px;
    }
  }
  @media only screen and (max-width:525px){
    .sale-text button {
      font-size: 15px !important;
      padding: 6px 20px;
  }

  }
