.adreress-left {
    border: 1px solid #AA8C72;
    border-radius: 5px;
    width:100%;
    height: 400px;
    padding: 20px;
    overflow: scroll;
}
.adreress-left h6 {
    font-size: 20px;
    font-weight: bold;
}
.adreress-right{
    border:1px solid #AA8C72;;
    width:100%;
    height: 400px;
    padding: 20px;
    border-radius: 5px;
    overflow: scroll;
}
.address-left-side{
    font-size: 15px;
   color :black;

    align-items: center;
    
}
.address-left-border-bottom{
    /* border-bottom: 1px solid#AA8C72 */
}
.addres-left-heading{
    font-size: 16px !important;
    font-weight: 600;
}
.address-left-side p {
    margin-bottom: 0px !important;
    font-size: 16px
;
}
.address-left-image{
    height: 150px;
    width: 100%;
}
.address-left-image img{
    height: 100%;
    width:100%;
    border-radius: 5px;
}
.checkoutquntity{
    width: 80px;
    border: 1px solid black;
    padding: 5px 10px;
    border-radius: 5px;

    color:black;
}
.checkoutRemove{
     font-size: 16px;
     color: red;
     font-weight: 400;
}
.checkOut-bottom-box{
    /* height: 400px; */
    border: 1px solid #AA8C72;
    width:100%;
    border-radius: 5px;
    padding: 20px;
}
.checkOut-bottom-box h6{
    font-size: 20px;
    font-weight: bold;
}
.checkout-iteam{

    align-items: center;
    margin: 15px 0px;
}
.checkout-iteam p{
    margin-bottom: 0px;
}
.cheachout-heading{
    font-size: 16px;
    font-weight: 600;
}

.checkout-iteam-total{
   border-top: 1px solid #AA8C72;
}

.adreress-right-heading {
    margin-bottom: 20px;
}

.adreress-right-heading h6{
    font-size: 20px;
    font-weight: bold;

}
.right-address-border{
    border: 1px solid#AA8C72;
    margin-top: 5px;
    padding: 20px;
    border-radius: 5px;
}
.formcheckinput-right{
    border: 2px solid #AA8C72
}
.right-button-address{
    background-color: #AA8C72;
    border: 1px solid  #AA8C72;
}
.paymant-box {
    margin: 10px;
    padding: 20px;
    border: 1px solid #AA8C72;
    border-radius: 5px;
    width: 96%;
    height: auto;
}
.paymant-box-headding h6{
    font-size: 20px;
    font-weight: bold;
}
.pay-button{
    display: flex;
    justify-content: center;
    margin-top: 20px;
;
}
.pay-button button{
    background-color: #AA8C72;
    border: 1px solid #AA8C72;
    width: 200px;
}
.checkouthr{
    color:#AA8C72;
    opacity: 1;

margin-top: 0px;

}






/* responsive   768 px*/
@media (min-Width:550px) and (max-Width:850px){
    .adreress-right-heading h6 {
        font-size: 15px;
        font-weight: bold;
    }
    .right-button-address {
        background-color: #AA8C72;
        border: 1px solid #AA8C72;
        font-size: 13px;
    }
    .adreress-left h6 {
        font-size: 16px;
        font-weight: bold;
    }
    .addres-left-heading {
        font-size: 14px !important;
        font-weight: 600;
    }
    .address-left-side p {
        margin-bottom: 0px !important;
        font-size: 13px;
    }
    
    .address-right-iteam{
        margin-left: 1rem !important;
        font-size: 14px; 
    }
    .checkOut-bottom-box h6 {
        font-size: 16px;
        font-weight: bold;
    }

.paymant-box-headding h6 {
    font-size: 16px;
    font-weight: bold;
}
.checkout-iteam p {
    margin-bottom: 0px;
    font-size: 14px;
}
.formcheck-paytam{
    font-size: 14px !important;
}
.paymant-box {
    width: 94%;
}
}


/* responsive 500 */
@media only screen and (max-Width:530px){
    .adreress-right-heading h6 {
        font-size: 15px;
        font-weight: bold;
    }
    .right-button-address {
        background-color: #AA8C72;
        border: 1px solid #AA8C72;
        font-size: 13px;
    }
    .adreress-left h6 {
        font-size: 16px;
        font-weight: bold;
    }
    .addres-left-heading {
        font-size: 14px !important;
        font-weight: 600;
    }
    .address-left-side p {
        margin-bottom: 0px !important;
        font-size: 13px;
    }
    
    .address-right-iteam{
        margin-left: 1rem !important;
        font-size: 14px; 
    }
    .checkOut-bottom-box h6 {
        font-size: 16px;
        font-weight: bold;
    }

.paymant-box-headding h6 {
    font-size: 16px;
    font-weight: bold;
}
.checkout-iteam p {
    margin-bottom: 0px;
    font-size: 14px;
}
.formcheck-paytam{
    font-size: 14px !important;
}
#address-left-border{
    width:100%
}
#address-left-border-1{
    width:100%;
    margin-top: 10px;
}
.paymant-box {
    width: 95%;
}
}
/* responsive 500 */
@media only screen and (max-Width:375px){
    .adreress-right-heading h6 {
        font-size: 13px;
        font-weight: bold;
    }
    .right-button-address {
        background-color: #AA8C72;
        border: 1px solid #AA8C72;
        font-size: 12px;
    }
    .adreress-left h6 {
        font-size: 13px;
        font-weight: bold;
    }
    .addres-left-heading {
        font-size: 12px !important;

    }
    .address-left-side p {
        margin-bottom: 0px !important;
        font-size: 12px;
    }
    
    .address-right-iteam{
        margin-left: 1rem !important;
        font-size: 13px; 
    }
    .checkOut-bottom-box h6 {
        font-size: 13px;
        font-weight: bold;
    }
    .paymant-box {
        width: 94%;
    }
.paymant-box-headding h6 {
    font-size: 13px;
    font-weight: bold;
}
.checkout-iteam p {
    margin-bottom: 0px;
    font-size: 12px;
}
.formcheck-paytam{
    font-size: 12px !important;
}

.checkout-iteam-total {
    border-top: 1px solid #AA8C72;
    font-size: 13px;
}
}

