
.contact-1{
    height:auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    /* border: 1px solid black; */
}
.contact-office{
    border: 1px solid rgba(170, 140, 114, 1);;
    padding: 20px 40px;
text-align: center;
margin: 5px;
border-radius: 10px;

}
.contact-office svg {
    font-size: 30px;
    color: rgba(170, 140, 114, 1);
}
.contact-office h5 {
    margin-top: 10px;
    font-weight: 600;
}
.contact-office p{
   font-size: 16px;
   color:rgb(101, 97, 97)
}

.contact-2{
    /* height:300px; */
    width: 600px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    border: 2px solid #F1EBE3;;
    border-radius: 10px;
    padding: 20px;
    background-color:#F1EBE3;;
}
.contacts-button{
    background-color:  black;
      border:1px solid black;
      color :white
     
}



@media only screen and (max-width:576px){
    .contact-2{
        width:300px;
    }
}