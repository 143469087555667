.navbar-logos img{
    height: 50px;
    cursor: pointer;
}

.navbar-menu-items ul{
    margin: 0px;
    padding: 0px;
    display: flex;
}
.navbar-menu-items ul li{
    width: 100%;
    padding: 10px 20px;
    list-style: none;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
}
.navbar-menu-items ul li:hover{
    background: #F1EBE3;
}
.navbar-last-items{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.navbar-search-field{
    width: 100%;
}
.navbar-search-field input{
    background: #F1EBE3;
    border-radius: 27px;
    border: none;
    outline: none;
    padding: 10px 0px 10px 50px;
    width: 100%;
}
.navbar-search-field svg{
    position: absolute;
    font-size: 18px;
    margin: 13px 0px 0px 20px;
}
.navbar-last-item-2 p{
    margin: 0px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
}
.navbar-last-item-2 {
    text-align: center;
    cursor: pointer;
}

.navbar-last-item-2 svg{
    color: #AA8C72;
    font-size: 20px;
}
.search-bar-result{
    position: absolute;
    background: white;
height: 250px;
overflow-y: scroll;
box-shadow:0px 5px 10px 1px#aa8c728c;
scrollbar-color: transparent transparent;

}
.search-bar-result::-webkit-scrollbar{
    display: none;
}
/* .navbar-search-field-1{

} */
.toggelmenuheading{
    width:90%;
    position: absolute;
    transition:2s;
    /* top:85px; */
    /* width: 96.9%; */
     top:0px;
     /* right: 36px; */
    /* -webkit-transition: all 0.5s ease-out; */
    /* transform: translate(20px, 20px); */
    background-color: #AA8C72;
    color: white;
height: 100vh;
    flex-direction: column;
    display: flex;
    justify-content: left;
    align-items: left;
    padding-left: 20px;
    

    /* padding-left:20px; */
    /* transform: translate(2%, 0px) !important; */

    right: auto !important;

   z-index: 99999;
}
.navbar-toggle-section:active .toggelmenuheading{
    transform: translate(100%, 0px) !important;

}
.catDropdown{
    position: relative;
}
.catDropdown-content{
position: absolute;
top:35px;
left:-15%;
width: 200px;
max-height: 400px;
padding: 10px;
background: white;
margin-top: 10px;
overflow-y: scroll;
z-index: 99999;
}
.catDropdown-content::-webkit-scrollbar{
    display: none;
}
.catDropdownhover:hover{
    background-color: #F1EBE3;
}

.catDropdown-content-small{
height: 130px;
overflow-y: scroll;
}
.catDropdown-content-small::-webkit-scrollbar{
    display: none;
}
.catDropdown-content-small:hover{
    background-color: #F1EBE3;
}
/* responsive 1024px */
@media only screen and (max-width:1024px){
    .navbar-menu-items ul li{
        font-size: 15px;
        padding: 10px 10px ;
    }
      

.navbar-search-field input {
    font-size: 15px;
}
}
/* responsive 768 */
@media only screen and (max-width:768px){
    /* .col-md-5.col-lg-3.d-flex.align-items-center.navbar-search-section{
        width: 50% !important;
        max-width:50% !important;
    } */
    .navbar-search-section{
        width: 50% !important;
    }
    .navbar-toggle-section{
        width: 16% !important;
    }
}
 /* responsive  525 */
 @media only screen and (max-width:525px){
    /* .navbar-search-section{
        display: none;
    } */
}