body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-check-input:checked{
  background-color: rgb(157, 131, 98);
  border-color: rgb(157, 131, 98);
}
.btn-dark:active{
  background-color: rgb(157, 131, 98) !important;
  border-color: rgb(157, 131, 98) !important;
}
.btn-dark:hover{
  background-color: rgb(157, 131, 98) !important;
  border-color: rgb(157, 131, 98) !important;
}

@media only screen and (max-width: 430px){
.modalpopup{
  width: 300px !important;
  
}
}