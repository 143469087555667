.topsale{
    padding: 50px 0px;
}

.heading-topsale p{
    color: grey;
}
.heading-topsale h2{
    font-weight: 600;
}
.heading-topsale span{
    color: #AA8C72;
}
.topsale-tab-list {

}

.topsale-tab-list ul{
padding: 0px;
margin: 0px;
display: flex;
justify-content: space-between;
background: rgba(217, 217, 217, 0.2);
border: 1.35px solid #AA8C72;
border-radius: 40.5px;
padding: 6px 20px;
margin: 20px 0px 0px 0px;
}
.topsale-tab-list ul li{
padding: 6px 35px;
list-style: none;
cursor: pointer;
font-size: 14px;

}

.select-topsale-item{
    background-color: #AA8C72;
    border-radius: 40.5px;
    color: #fff;
}

.topsale-line{
    border: 1px solid black;
    height: 3px;
    width: 20%;
    display: inline-block;
    background-color: black;
}
.topsale-dot{
    border: 1px solid  #AA8C72;
    height: 12px;
    width: 12px;
    display: inline-block;
    background-color:  #AA8C72;
    border-radius: 50%;
    position: relative;
    left: 10%;
    top: 3%;
}


/* responsive cssssss  1024*/
@media only screen and (max-width:1024px){
    
}
/* responsive cssssss  768*/
@media only screen and (max-width:768px){
    .topsale-tab-list ul{
        
        padding: 6px 10px;

        }
    .topsale-tab-list ul li{
        padding: 6px 15px;
        list-style: none;
        cursor: pointer;
        font-size: 14px;
        
        }
        .sale-text button {
        font-size: 10px;
        }
}
/* responsive cssssss  525*/
@media only screen and (max-width:525px){
   
    .select-topsale-item {
        width:150px;
        height: 50px;
        border-radius: 30.5px;
        
    }
    .topsale-tab-list ul li {

       
        min-width: 150px;
        padding: 10px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
        .topsale-tab-list ul{
            padding:  0px !important;
            margin: 0px;
    overflow-x: scroll;

       
            display: flex;  


            background: rgba(217, 217, 217, 0.2);

            border: none;


            padding: 6px 20px;
            margin: 20px 0px 0px 0px;
            }
            .topsale-tab-list ul::-webkit-scrollbar{
               display: none;
            }
}
@media only screen and (max-width:350px){
    /* .topsale-tab-list ul li {
        font-size: 11px;
    } */
}