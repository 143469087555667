.addreview{
    padding: 20px;
    width: 95%;
}
.addreview h5 {
    font-weight: bold;
    margin-top: 20px;
}
.addreview-star svg{
    font-size: 25px;
}
.form-control:focus{
    box-shadow: none;
    border-color:  #AA8C72;
}
.addreviewImagplus{
    position: absolute;
    top:43%;
    left: 43%;
}
.addreviewImag{
    height: 100px;
    width: 100px;
    position: relative;
}
@media screen and (max-width:375px) {
    .addreviewImag{
        height: 80px;
        width: 80px;
    }
}