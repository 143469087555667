.todaypopularsale{
    padding: 50px 0px;
    width:100%
}

.heading-todaypopularsale p{
    color: grey;
}
.heading-todaypopularsale h2{
    font-weight: 600;
}

.todaypopularsale-line{
    border: 1px solid black;
    height: 3px;
    width: 20%;
    display: inline-block;
    background-color: black;
}
.todaypopularsale-dot{
    border: 1px solid  #AA8C72;
    height: 12px;
    width: 12px;
    display: inline-block;
    background-color:  #AA8C72;
    border-radius: 50%;
    position: relative;
    left: 10%;
    top: 3%;
}

.todaypopularsale-sale-text{
    color: #AA8C72;
}

.timer {
    display: flex;
    align-items: center;
    border: 1px solid #AA8C72;
    padding: 5px;
    justify-content: center;
}


.owl-carousel .owl-item img{
    border-radius: 50% !important;
    height: 200px;
    width: 200px;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15) !important;
    margin: auto;
}
.todaypopularsale-main {
    text-align: center;
}

.todaypopularsale-main h5{
    margin: 22px 0px 0px 0px;
    font-weight: 600;
}
.todaypopularsale-main span{
    font-size: 14px;
    color: grey;
}


@media only screen and (max-width:1024px){
    .owl-carousel .owl-item img{
        border-radius: 50% !important;
        height: 170px;
        width: 170px;
        box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15) !important;
        margin: auto;
    }
    .timer{
        width: 100%;
    } 
}
@media only screen and (max-width:768px){
    .owl-carousel .owl-item img{
        border-radius: 50% !important;
        height: 100px;
        width: 100px;
        box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15) !important;
        margin: auto;
    }
    .timer{
        width: 100%;
    } 
}
@media only screen and (max-width:525px){
    .owl-carousel .owl-item img{
        border-radius: 50% !important;
        height: 70px;
        width: 70px;
        box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15) !important;
        margin: auto;
    }
    .todaypopularsale-view-all h5{
        margin-top: 10px;
        font-size: 14px;
    }
    .todaypopularsale-main h5{
        font-size: 14px;
    }
    
}
@media only screen and (max-width:375px){
    .owl-carousel .owl-item img{
        border-radius: 50% !important;
        height: 50px;
        width: 50px;
        box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15) !important;
        margin: auto;
    }
    
}