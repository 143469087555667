.topbar {
    background: #AA8C72;
}
.topbar-main-1{
    display: flex;
    justify-content: start;
    color: #fff;
}

.topbar-main-2{
    display: flex;
    justify-content: end;
    color: #fff;
}

.topbar-main-1 span{
    padding: 5px 10px;
}
.topbar-main-2 span{
    padding: 5px 10px;
    cursor: pointer;
}


.langaugae-dropdown{
    background-color: #AA8C72;
    position: absolute;
    border: 1px solid #dcdcdc;
    margin: 33px 60px 0px 0px;
    z-index: 99;
}
.langaugae-dropdown ul {
    margin: 0px;
    padding: 0px;
}
.langaugae-dropdown ul li{
    list-style: none;
    width: 100%;
    padding: 8px 30px;
    cursor: pointer;
    transition: 0.6s;
}
.langaugae-dropdown ul li:hover{
    background-color: #000;
    color: #fff;
}
/* responsive styel 1024 */
@media only screen and (max-width:1024px){
    .topbar {
        height: 50px;
    }
    .topbar-main-1 span{
        font-size: 13px;
    }
    .topbar-main-2 span {
        font-size: 13px;
    }
}

/* responsive styel 780 */
@media only screen and (max-width:768px){
    .topbar {
        height: 30px;
    }
    .topbar-main-1 span{
        font-size: 9px;
    }
    .topbar-main-2 span {
        font-size: 9px;
    }
    .langaugae-dropdown {
    margin: 33px 60px 0px 0px;
       
        /* margin: 16px 13px 0px 0px; */
        z-index: 99;
        font-size: 12px;
    }
    .langaugae-dropdown ul li{
        
        padding: 2px 10px;
        cursor: pointer;
        transition: 0.6s;
    }
}
@media only screen and (max-width:525px){
    .topbar {
        height: 60px;
    }
    #topbarmenu-6{
        width:100%
    }
    .topbar-main-1 span{
        font-size: 11px;
        padding: 5px 5px;
    }
    .topbar-main-2 span {
        font-size: 11px;
        padding: 5px 5px;
    }
    #topbarmenu-2{
        display: flex;
        align-items: start;
        width: 100%;
    }
    .langaugae-dropdown {
        background-color: #AA8C72;
        position: absolute;
        border: 1px solid #dcdcdc;
        margin: 16px 13px 0px 0px;
        z-index: 99;
        font-size: 12px;
    }
    .langaugae-dropdown ul li{
        list-style: none;
        width: 100%;
        padding: 2px 6px;
        cursor: pointer;
        transition: 0.6s;
    }
}
@media only screen and (max-width:375px){
    .topbar{
        height: 60px;
    }
    .topbar-main-1 span {
        font-size: 11px;
        padding: 5px 2px;
    }
    #topbarmenu-6{
        width:100%
    }
    #topbarmenu-2{
        display: flex;
        align-items: start;
        width: 100%;
    }
    .topbar-main-2 span{
        font-size: 11px;
    }
}